@media (max-width: 1280px) {
  .sm\:sm\:fw-400{
    font-weight: 400;
  }
  .sm\:fw-500{
    font-weight: 500;
  }
  .sm\:fw-600{
    font-weight: 600;
  }
  .sm\:fw-700{
    font-weight: 700;
  }
  .sm\:fw-800{
    font-weight: 800;
  }
  .sm\:fw-900{
    font-weight: 900;
  }
  .sm\:fz-13{
    font-size: 13px;
  }
  .sm\:fz-16{
    font-size: 16px;
  }
  .sm\:fz-17{
    font-size: 17px;
  }
  .sm\:fz-20{
    font-size: 20px;
  }
  .sm\:fz-22{
    font-size: 22px;
  }
  .sm\:fz-23{
    font-size: 23px;
  }
  .sm\:fz-24{
    font-size: 24px;
  }
  .sm\:fz-27{
    font-size: 27px;
  }
  .sm\:fz-40{
    font-size: 40px;
  }
}
@media (min-width: 640px) {
  .sm\:max-w-2xl	{max-width: 42rem;}
  .sm\:max-w-3xl	{max-width: 48rem;}
  .sm\:max-w-4xl	{max-width: 56rem;}
  .sm\:max-w-4-5xl	{max-width: 60rem;}
  .sm\:max-w-5xl	{max-width: 64rem;}
  .sm\:max-w-6xl	{max-width: 72rem;}
  .sm\:max-w-7xl	{max-width: 80rem;}
}