@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;600;700;800;900&display=swap');

:root {
  --toastify-font-family: inherit !important; /*override font family of toast, extend parent font*/
}

html{
  font-family: 'Noto Sans JP', sans-serif;

  color: #121A1E;
}
thead {
  font-weight: 600;
  font-size: 16px;
  color: #121A1E;
}
thead {
  tr{
    td{
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: #121A1E;
    }
  }
}
table{
  thead{
    tr{
      background-color: #E3E4E4;
    }
    border: 0;
  }
}
.bd-0{
  border: 0;
}
.page-title {
  font-weight: 700 !important;
  font-size: 40px !important;
  line-height: 60px;
  letter-spacing: 0.36px;
  }
  @media only screen and (max-width:768px) {
    .page-title {
      font-weight: 600 !important;
      font-size: 22px !important;
      line-height: 33px;
    }
  }
  .btn-normal{
    border-radius: 12px;
  }
  input::file-selector-button {
    border-radius: 12px;
    height: 48px;
    background-color: white;
    width: 144px;
  }

.change-pass-form{
  .logo{
    svg{
      path{
        fill: #5080FF;
      }
      margin: 0 auto;
    }
  }
}
.icon-profile-menu{
  width: 1.146rem;
  height: 1.146rem;
}
tr .text-sm{
  font-size: 16px;
}
.MuiTabs-root button{
  outline: none;
  font-size: 16px;
}
.w-r-20{
  width: 20rem;
}
.fw-400{
  font-weight: 400;
}
.fw-500{
  font-weight: 500;
}
.fw-600{
  font-weight: 600 !important;
}
.fw-900{
  font-weight: 900;
}
.fw-700{
  font-weight: 700;
}
.fw-800{
  font-weight: 800;
}
.fz-13{
  font-size: 13px;
}
.fz-14{
  font-size: 14px;
}
.fz-15{
  font-size: 15px;
}
.fz-16{
  font-size: 16px;
}
.fz-17{
  font-size: 17px !important;
  .MuiListItemText-primary{
    font-size: 17px !important;
  }
}
.fz-19{
  font-size: 19px;
}
.fz-20{
  font-size: 20px;
}
.fz-22{
  font-size: 22px;
}
.fz-23{
  font-size: 23px;
}
.fz-24{
  font-size: 24px;
}
.fz-27{
  font-size: 27px;
}
.fz-28{
  font-size: 28px;
}
.fz-27{
  font-size: 27px;
}
.fz-40{
  font-size: 40px;
}
.MuiListItemText-root span.MuiListItemText-primary{
  font-size: 17px;
  font-weight: 400;
}
